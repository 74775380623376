import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const MainPage = React.lazy(() => import("./pages/MainPage"));
const CatalogPage = React.lazy(() => import("./pages/CatalogPage"));
const OrderPage = React.lazy(() => import("./pages/OrderPage"));
const NdflPage = React.lazy(() => import("./pages/NdflPage"));
const PromoPage = React.lazy(() => import("./pages/PromoPage"));
const SuccessPage = React.lazy(() => import("./pages/SuccessPage"));
const ApprovePage = React.lazy(() => import("./pages/ApprovePage"));
const FailPage = React.lazy(() => import("./pages/FailPage"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const AboutITPage = React.lazy(() => import("./pages/AboutITPage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const PartnerPage = React.lazy(() => import("./pages/PartnerPage"));
const PaymentPage = React.lazy(() => import("./pages/PaymentPage"));
const RefundPage = React.lazy(() => import("./pages/RefundPage"));
const InstallPage = React.lazy(() => import("./pages/InstallPage"));
const LoadingPage = React.lazy(() => import("./pages/LoadingPage"));
const AuthorPage = React.lazy(() => import("./pages/AuthorPage"));
const SubscribePage = React.lazy(() => import("./pages/SubscribePage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
import { useUrlHash } from "@hooks";

function App() {
  useUrlHash();

  return (
    <>
      <Suspense fallback={<div>Подождите...</div>}>
        <Routes>
          <Route path={"/"} element={<MainPage />} />
          <Route path={"/catalog"} element={<CatalogPage />} />
          <Route path={"/order"} element={<OrderPage />} />
          <Route path={"/order/:product"} element={<OrderPage />} />
          <Route path={"/promo/:product"} element={<PromoPage />} />
          <Route path={"/success/:number"} element={<SuccessPage />} />
          <Route path={"/approve"} element={<ApprovePage />} />
          <Route path={"/loading"} element={<LoadingPage />} />
          <Route path={"/fail/:product"} element={<FailPage />} />
          <Route path={"/ndfl"} element={<NdflPage />} />
          <Route path={"/about"} element={<AboutPage />} />
          <Route path={"/about-it"} element={<AboutITPage />} />
          <Route path={"/contact"} element={<ContactPage />} />
          <Route path={"/partner"} element={<PartnerPage />} />
          <Route path={"/payment"} element={<PaymentPage />} />
          <Route path={"/refund"} element={<RefundPage />} />
          <Route path={"/ustanovka"} element={<InstallPage />} />
          <Route path={"/author"} element={<AuthorPage />} />
          <Route path={"/subscribe"} element={<SubscribePage />} />
          <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
