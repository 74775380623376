import { useEffect, useState } from 'react';


export const useCategories = (products) => {
    const [categoriesSlug, setCategoriesSlug] = useState([]);
    const [categoriesName, setCategoriesName] = useState([]);

    useEffect(() => {
        if (products) {
            let setSlug = new Set();
            let setName = new Set();

            products.forEach(item => {
                item.categories.forEach(cat => {
                  setSlug.add(cat?.slug)
                  setName.add(cat?.name)
                })
            });

            let arrSlug = Array.from(setSlug);
            let arrName = Array.from(setName);
            setCategoriesSlug(arrSlug);
            setCategoriesName(arrName);
            arrSlug.unshift('all');
            arrName.unshift('Все');
        }
    }, [products]);

    return [categoriesSlug, categoriesName]
};
